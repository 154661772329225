import { ListItem } from '@ceres/frontend-helper';

const projectListColumns: ListItem[] = [
    { title: 'mpNumber', selected: true },
    { title: 'mpTitle', selected: true },
    { title: 'businessPartner.fullName', selected: true },
    { title: 'abteilungKunde', selected: true },
    { title: 'kundenart', selected: false },
    { title: 'projectLead.name', selected: true },
    { title: 'plannedHours', selected: false },
    { title: 'actualHours', selected: false },
    { title: 'fsgGesamtprojekt', selected: true },
    { title: 'fsgExtern', selected: true },
    { title: 'projectStatus', selected: true },
    { title: 'verrechnungsart', selected: true },
    { title: 'verrechnungszyklus', selected: true },
    { title: 'maturityLevel.translationKey', selected: true },
    { title: 'bestellwert', selected: true },
    { title: 'auftragswert', selected: true },
    { title: 'pocValue', selected: true },
    { title: 'leistungsempfaenger.title', selected: true },
    { title: 'plannedExternalCosts', selected: false },
    { title: 'istkostenExtern', selected: true },
    { title: 'startDate', selected: true },
    { title: 'endDate', selected: true },
    { title: 'orgID', selected: true },
    { title: 'costCenterInternal', selected: true },
    { title: 'customerPspElement.title', selected: true },
    { title: 'verrechnungsland', selected: false },
    { title: 'bestellnummer', selected: false },
    { title: 'projectComment', selected: false },
    { title: 'abgerechneterWert', selected: false },
    { title: 'partnerDepthStructure', selected: false },
    { title: 'contractNumber', selected: false },
    { title: 'forecastCostsOverallFY', selected: false },
    { title: 'forecastCostsExternalFY', selected: false },
    { title: 'offerNumber', selected: false },
    { title: 'projektbeschreibung', selected: false },
    { title: 'projectType.translationKey', selected: true }
];

export default projectListColumns;
